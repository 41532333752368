@font-face {
  font-family: 'ModeratJIT';
  src: local('ModeratJIT'), url(./fonts/ModeratJIT-Regular.ttf) format('woff');
}

.Toastify__toast--error {
  background: #ff0808;
  border-radius: 4px;
  align-items: center;
}
.Toastify__toast--success {
  background: #4caf50;
  border-radius: 4px;
  align-items: center;
}
.Toastify__toast-body {
  white-space: pre-line;
  color: white;
}

.Toastify__toast-container {
  width: 400px;
  z-index: 9999;
}
